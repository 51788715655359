// Bootstrap
import "bootstrap";
// Popovers
// Note: Disable this if you're not using Bootstrap's Popovers
$('[data-toggle="popover"]').popover();
// Tooltips
// Note: Disable this if you're not using Bootstrap's Tooltips
$('[data-toggle="tooltip"]').tooltip();

// Toaster
import toastr from "toastr/toastr";
window.toastr = toastr;

// Select2
import "select2/dist/js/select2.min";